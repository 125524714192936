<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    modelValue: String,
    state: {
        type: Boolean,
        default: true,
    },
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        class="focus:border-theme-300 focus:ring focus:ring-theme-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:text-gray-500 disabled:bg-gray-200 disabled:border-gray-400 disabled:cursor-not-allowed"
        :class="state === false ? 'border-red-400' : 'border-gray-300'"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
</template>
